<script setup lang="ts">
import InViewObserver from '../../observers/InViewObserver'
import Container from '@components/Container.vue'
import type { Intersection } from '../../observers/InViewObserver'
import { onMounted, ref } from 'vue'

const target = ref<Element>()
const visible = ref(false)

const observer = new InViewObserver(
  (intersection: Intersection) => {
    if (!visible.value) {
      visible.value = intersection.visible
    }
  },
  '#app' ?? undefined,
  false
)

onMounted(() => {
  observer.observe(target.value as Element)
})
</script>

<template>
  <Container>
    <Block>
      <div ref="target">
        <Grid ref="target">
          <div
            class="duration-700 delay-500 ease-in col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-6"
            :class="visible ? 'translate-y-0 opacity-100' : 'translate-y-1/2 opacity-0'"
          >
            <div class="flex flex-row justify-start gap-2 md:gap-4 w-full">
              <div
                class="relative flex-none w-10 h-10 overflow-hidden bg-white text-neutral rounded-full"
              >
                <svg
                  class="absolute w-12 h-12 text-gray-400 -left-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div
                class="px-8 py-6 border w-1/2 md:w-auto text-primary md:text-xl shadow-md bg-white rounded-r-2xl rounded-b-2xl border-white min-w-[50%] lg:min-w-[60%]"
              >
                <slot name="question"></slot>
              </div>
            </div>
          </div>
          <div
            class="duration-700 delay-[2s] mt-4 md:mt-8 col-span-full md:col-start-3 md:col-span-4 lg:col-start-6 lg:col-span-7"
            :class="visible ? 'translate-y-0  opacity-100' : 'translate-y-1/2  opacity-0'"
          >
            <div class="flex flex-row gap-2 md:gap-4 w-full justify-end">
              <div
                class="px-8 py-6 w-1/2 md:w-auto border flex ease-in text-primary md:text-xl shadow-md bg-white rounded-l-2xl rounded-b-2xl border-white min-w-[50%] lg:min-w-[60%]"
              >
                <slot name="answer"></slot>
              </div>
              <div class="relative w-10 h-10 overflow-hidden bg-white text-neutral rounded-full">
                <svg
                  class="absolute w-12 h-12 text-gray-400 -left-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </Grid>
      </div>
      <div class="flex justify-center text-white">
        <slot name="info"></slot>
      </div>
    </Block>
  </Container>
</template>
