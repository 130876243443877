<script setup lang="ts">
import Block from '../Block.vue'
import Grid from '../Grid.vue'
import Container from '@components/Container.vue'
import InViewObserver from '../../observers/InViewObserver'
import type { Intersection } from '../../observers/InViewObserver'
import { onMounted, ref } from 'vue'

const target = ref<Element>()
const visible = ref(false)

const observer = new InViewObserver(
  (intersection: Intersection) => {
    if (!visible.value) {
      visible.value = intersection.visible
    }
  },
  '#app' ?? undefined,
  false
)

onMounted(() => {
  observer.observe(target.value as Element)
})
</script>
<template>
  <div class="h-0">
    <svg viewBox="0 0 1600 136" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="cutOut" clipPathUnits="objectBoundingBox">
          <path d="M0,0.871 L1,0 L1,1 L0,1 L0,0.871" fill="#000" />
        </clipPath>
      </defs>
    </svg>
  </div>
  <div class="clip-cutout relative w-full" ref="target">
    <div class="aspect-[1600/136] w-full"></div>
    <Container class="w-full bg-purple flex justify-center">
      <Block>
        <Grid>
          <div
            class="col-span-full md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-5 flex flex-col items-center justify-center duration-700 delay-500"
            :class="visible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-y-24'"
          >
            <slot name="column1"></slot>
          </div>
          <div
            class="col-span-full md:col-start-4 md:col-span-3 lg:col-start-8 lg:col-span-4 flex flex-col items-center justify-center"
          >
            <slot name="column2"></slot>
          </div>
        </Grid>
      </Block>
    </Container>
    <div class="aspect-[1600/136] w-full"></div>
  </div>
</template>

<style scoped>
.clip-cutout::after {
  content: ' ';
  -webkit-clip-path: url(#cutOut);
  clip-path: url(#cutOut);
  aspect-ratio: 1600/136;
  transform: rotate(180deg);
  margin-bottom: 1px;
  @apply absolute left-0 w-full bottom-0 bg-purple;
}
.clip-cutout::before {
  content: ' ';
  -webkit-clip-path: url(#cutOut);
  clip-path: url(#cutOut);
  aspect-ratio: 1600/136;
  margin-top: 1px;
  @apply absolute top-0 left-0 w-full bg-purple;
}
</style>
